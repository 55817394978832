<template>
  <div class="option-buttons">
    <pn-button
      small
      appearance="light"
      icon-only
      :icon="isFolded ? expand : bars"
      @click="toggle"
    >
      Toggle
    </pn-button>
  </div>
</template>

<script>
import { expand, bars } from '@/shared/constants/icons.js'

export default {
  name: 'FoldControl',
  props: {
    isFolded: Boolean
  },
  emits: ['didToggle'],
  data() {
    return {
      expand,
      bars
    }
  },
  methods: {
    toggle() {
      this.$emit('didToggle', !this.isFolded)
    }
  }
}
</script>
