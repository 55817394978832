<template>
  <div class="content">
    <MainHeader :title="content.title" :description="content.description" />
    <div class="right-content">
      <pn-button
        :key="componentKey"
        appearance="light"
        :icon="alert_info_circle"
        small
        @click="clickHelp"
      >
        {{ $t('APP.HELP') }}
      </pn-button>
      <pn-search-field
        label="Search"
        :placeholder="$t('CAPABILITIES.SEARCH_PLACEHOLDER')"
        :disabled="loading"
        :loading="loading"
        :value="draftFilterSsid"
        @search="search"
        @update="draftFilterSsid = $event.target.value"
      />
      <PreviousSearches
        :items="items"
        @did-clicked-previous-search="handleDidClickPreviousSearch"
        @did-clear-previous-searches="handleDidClearPreviousSearches"
      />
    </div>
  </div>
</template>

<script>
import { isValidSsid } from '@/shared/utils/capabilityUtils'
import {
  getSessionSearches,
  saveToSessionSearches,
  clearSessionSearches
} from '@postnord/retail-backoffice-components/dist'
import useToast from '@/shared/composables/useToast'
import PreviousSearches from '@/shared/components/PreviousSearches.vue'
import {
  alert_info_circle,
  alert_exclamation_circle
} from '@/shared/constants/icons.js'

export default {
  name: 'CapabilityHeader',
  components: {
    PreviousSearches
  },
  props: {
    content: {
      type: Object,
      default() {
        return {
          title: 'Capabilities',
          description: 'Manage system-wide configurations'
        }
      }
    },
    loading: Boolean,
    filterSsid: {
      type: String,
      default() {
        return ''
      }
    },
    componentKey: {
      type: Number,
      default: 0
    }
  },
  emits: ['didSearch', 'didClickHelp'],
  setup() {
    const { showToaster } = useToast()
    return {
      showToaster
    }
  },
  data() {
    return {
      draftFilterSsid: '',
      previousSearchesLocalKey: 'ssidSearches',
      items: [],
      utils: window.retailBackofficeUtils,
      alert_info_circle,
      alert_exclamation_circle
    }
  },
  computed: {
    isValid() {
      return isValidSsid(this.draftFilterSsid)
    }
  },
  watch: {
    filterSsid(val) {
      this.draftFilterSsid = val
    }
  },
  mounted() {
    this.items = getSessionSearches(this.previousSearchesLocalKey)
  },
  methods: {
    search() {
      if (this.isValid) {
        this.draftFilterSsid = this.draftFilterSsid.toUpperCase()
        saveToSessionSearches(
          { filterName: 'filterSsid', value: this.draftFilterSsid },
          this.previousSearchesLocalKey
        )
        this.items = getSessionSearches(this.previousSearchesLocalKey)
        this.$emit('didSearch', this.draftFilterSsid)
      } else {
        this.showInvalidSearch()
      }
    },
    showInvalidSearch() {
      this.showToaster({
        showClose: true,
        icon: alert_exclamation_circle,
        appearance: 'warning',
        message: `Please input a valid Source ID format (i.e. SE020001)`,
        show: true
      })
    },
    handleDidClickPreviousSearch(item) {
      this.draftFilterSsid = item.value
      this.$emit('didSearch', this.draftFilterSsid)
    },
    handleDidClearPreviousSearches() {
      this.items = []
      this.draftFilterSsid = ''
      clearSessionSearches(this.previousSearchesLocalKey)
    },
    clickHelp() {
      this.$emit('didClickHelp')
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  justify-content: left;
  align-items: left;
}
.right-content {
  height: inherit;
  text-align: right;
  padding: 1em 0;
  min-width: 50%;

  pn-search-field {
    margin-top: 1.5em;
    width: 100%;
  }
}
</style>
