import { createI18n } from 'vue-i18n'

import daApp from './da/strings.json'
import enApp from './en/strings.json'
import fiApp from './fi/strings.json'
import noApp from './no/strings.json'
import svApp from './sv-SE/strings.json'

import daCapabilities from './Capabilities/da/strings.json'
import enCapabilities from './Capabilities/en/strings.json'
import fiCapabilities from './Capabilities/fi/strings.json'
import noCapabilities from './Capabilities/no/strings.json'
import svCapabilities from './Capabilities/sv-SE/strings.json'

const en = {
  ...enApp,
  ...enCapabilities
}
const sv = {
  ...svApp,
  ...svCapabilities
}
const da = {
  ...daApp,
  ...daCapabilities
}
const fi = {
  ...fiApp,
  ...fiCapabilities
}
const no = {
  ...noApp,
  ...noCapabilities
}

const messages = { en, sv, da, fi, no }

const i18n = createI18n({
  legacy: false,
  locale: pnTopbar.language,
  fallbackLocale: 'en',
  globalInjection: true,
  messages
})

export default i18n
