import { watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export function usePreloadCapabilities() {
  const store = useStore()

  watch(
    () => store.state.accessToken,
    (newVal) => {
      if (newVal !== 'anonymous') {
        store.dispatch('capability/getCapabilities')
      }
    }
  )

  onMounted(() => {
    if (store.state.accessToken !== 'anonymous') {
      store.dispatch('capability/getCapabilities')
    }
  })
}
