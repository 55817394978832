export const typeIds = {
  3: {
    countrySupport: ['DK'],
    description: 'Erhvervsindleveerering'
  },
  4: {
    countrySupport: ['DK'],
    description: 'Postbutik (Post Store)'
  },
  6: {
    countrySupport: ['DK'],
    description: 'Posthus (Post office)'
  },
  20: {
    countrySupport: ['SE'],
    description: 'Brevterminal (Letter Sorting Terminal)'
  },
  22: {
    countrySupport: ['SE', 'DK', 'NO', 'FI'],
    description: 'Brevbärarkontor (Postal Delivery)'
  },
  24: {
    countrySupport: ['SE'],
    description: 'Företagscenter (Business Center)'
  },
  25: {
    countrySupport: ['SE'],
    description: 'Postombud (Post office)'
  },
  30: {
    countrySupport: ['SE'],
    description:
      'Distributions och Inhämtningspunkt (Distributions and Collection Point)'
  },
  38: {
    countrySupport: ['FI'],
    description: 'Hämtställe'
  },
  44: {
    countrySupport: ['DK'],
    description:
      'Postbutik med begrænset sortiment (Post store with limited assortment)'
  },
  46: {
    countrySupport: ['DK'],
    description: 'Erhvervscenter'
  },
  51: {
    countrySupport: ['SE', 'NO', 'DK', 'FI'],
    description: 'Collect in Store'
  },
  54: {
    countrySupport: ['SE', 'DK', 'FI', '54'],
    description: 'Utlämningsställe (Delivery)'
  },
  56: {
    countrySupport: ['SE'],
    description: 'Distributions Depåer'
  },
  58: {
    countrySupport: ['SE'],
    description: 'Distributionshubbar'
  }
}
