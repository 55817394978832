<template>
  <pn-modal :open="show" class="type-id-modal" @close="cancel">
    <div class="pn-modal-content">
      <header class="modal-card-head">
        <h3 class="modal-card-title">{{ $t('APP.HELP') }}</h3>
        <pn-select :value="selectedCountry" @selectchange="handleSelectChange">
          <pn-option
            v-for="(country, index) in countries"
            :key="index"
            :value="country"
          >
            {{ country }}
            <span
              class="flag-icon"
              :class="`flag-icon-${country.toLowerCase()}`"
            ></span>
          </pn-option>
        </pn-select>
      </header>
      <section class="modal-card-body">
        <p>
          Rules that identify if a business location download item is part of
          retail or not.
        </p>
        <ul class="type-id-list">
          <li v-if="!typeIdsOfSelectedCountry.length" class="type-id-item">
            <span class="type-id-desc"> No Available Information... </span>
          </li>
          <li
            v-for="(typeId, index2) in typeIdsOfSelectedCountry"
            :key="index2"
            class="type-id-item"
          >
            <div class="type-id">
              {{ typeId }}
            </div>
            <span v-if="typeIds[typeId]" class="type-id-desc">
              {{ typeIds[typeId].description }}
            </span>
          </li>
        </ul>
      </section>
    </div>
  </pn-modal>
</template>

<script>
import { typeIds } from '@/constants/typeIds'

export default {
  name: 'TypeIdModal',
  props: {
    show: Boolean
  },
  emits: ['didCancel'],
  data() {
    return {
      selectedCountry: 'SE',
      countries: ['SE', 'DK', 'FI', 'NO'],
      typeIds: typeIds
    }
  },
  computed: {
    typeIdsOfSelectedCountry() {
      return Object.keys(this.typeIds).filter((id) => {
        const item = this.typeIds[id]
        return item.countrySupport.includes(this.selectedCountry)
      })
    }
  },
  methods: {
    cancel() {
      this.$emit('didCancel')
    },
    handleSelectChange(event) {
      this.selectedCountry = event.detail
    }
  }
}
</script>

<style scoped lang="scss">
.modal-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flag-icon {
  margin: 0 4px;
}
ul.type-id-list {
  list-style: none;
}
.type-id {
  font-size: larger;
  color: pn-styles.$blue700;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 4px;
  background: #fff;
  border: 2px solid pn-styles.$blue700;
  text-align: center;
}
.type-id-item {
  padding-bottom: 1em;
  display: flex;
  grid-gap: 1em;
  align-items: center;
}
pn-select {
  min-width: 200px;
}
</style>
