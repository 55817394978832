<template>
  <div v-if="items && items.length" class="tags">
    <span class="label">{{ label }}: </span>
    <span
      v-for="(item, index) in reversedItems"
      :key="index"
      class="search-tag"
      @click="clickedItem(item)"
    >
      {{ item.value }}
    </span>
    <pn-button
      appearance="light"
      :icon="trash_can"
      icon-only
      small
      @click="clear()"
    >
      {{ clearLabel }}
    </pn-button>
  </div>
</template>

<script>
import { trash_can } from '@/shared/constants/icons.js'

export default {
  name: 'PreviousSearches',
  props: {
    label: {
      type: String,
      default() {
        return 'Previous Searches'
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    clearLabel: {
      type: String,
      default() {
        return 'Clear'
      }
    }
  },
  emits: ['didClickedPreviousSearch', 'didClearPreviousSearches'],
  data() {
    return {
      trash_can
    }
  },
  computed: {
    reversedItems() {
      if (this.items) {
        const copy = [...this.items]
        return copy.reverse()
      }
      return []
    }
  },
  methods: {
    clickedItem(item) {
      this.$emit('didClickedPreviousSearch', item)
    },
    clear() {
      this.$emit('didClearPreviousSearches')
    }
  }
}
</script>

<style scoped lang="scss">
.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0;

  .search-tag {
    margin-right: 8px;
    padding: 8px;
    font-size: 80%;
    border: 0.1rem solid pn-styles.$blue700;
    color: pn-styles.$blue900;
    background: pn-styles.$white;
    border-radius: 3em;
    cursor: pointer;
  }

  .search-tag:hover {
    background: pn-styles.$blue50;
  }

  .label {
    padding-right: 8px;
    padding-bottom: 8px;
    color: #969087; //$gray400
    font-size: 90%;
  }
}
</style>
