import capabilitiesService from '@/services/capabilitiesService'
import retailUtilService from '@/services/retailUtilService'
import { handleError } from '../toaster'

export default {
  namespaced: true,
  state: {
    loading: true,
    capabilities: [],
    servicepoint: null,
    permission: null
  },
  actions: {
    getPermissions: async ({ rootState, dispatch }) => {
      rootState.capability.loading = true
      try {
        const response = await retailUtilService.getPermissions()
        rootState.capability.permission = response.data ? response.data : null
      } catch (error) {
        handleError(dispatch, error)
      }
      rootState.capability.loading = false
    },
    getCapabilities: async ({ rootState, dispatch }) => {
      rootState.capability.loading = true
      try {
        const response = await capabilitiesService.getCapabilities()
        rootState.capability.capabilities = response.data ? response.data : []
      } catch (error) {
        handleError(dispatch, error)
      }
      rootState.capability.loading = false
    },
    updateCapability: async ({ rootState, dispatch, commit }, payload) => {
      rootState.capability.loading = true
      try {
        const encoded = JSON.stringify(payload)
        const response = await capabilitiesService.updateCapability(encoded)
        if (response.status === 200) {
          commit('updateCapabilitySuccess')
        }
      } catch (error) {
        handleError(dispatch, error)
      }
      rootState.capability.loading = false
    },
    getServicepointCapabilities: async ({ rootState, dispatch }, ssid) => {
      rootState.capability.loading = true
      try {
        const response = await capabilitiesService.getCapabilitiesOf(ssid)
        rootState.capability.servicepoint = response.data ? response.data : null
      } catch (error) {
        handleError(dispatch, error)
      }
      rootState.capability.loading = false
    },
    patchCapabilitySourceId: async (
      { rootState, dispatch, commit },
      payload
    ) => {
      rootState.capability.loading = true
      try {
        const { capabilityId, sourceId, value } = payload
        const response = await capabilitiesService.patchCapabilitySourceId(
          capabilityId,
          sourceId,
          value
        )
        if (response.status === 200) {
          commit('updateCapabilitySuccess')
        }
      } catch (error) {
        handleError(dispatch, error)
      }
      rootState.capability.loading = false
    }
  },
  mutations: {
    updateCapabilitySuccess() {
      // Do nothing, just for notification
    }
  }
}
