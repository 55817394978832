import { valueTypes } from '@/constants/valueTypes'

export function useValueTypeUtils() {
  const supportedValues = ['string', 'number', 'boolean']
  const basicArraySupportedIds = {
    'checkout.reasons': 'String',
    'handoverToPostNord.reasons': 'String',
    'delivery.identificationTypes.identificationType': 'Number',
    'return.returnReasons.returnReason': 'Number'
  }

  const getValueType = (capability) => {
    let type = 'undefined'
    if (capability) {
      const capabilityId = capability.capabilityId.toLowerCase()
      const defaultValue = capability.default
      if (valueTypes[capabilityId] !== undefined) {
        type = valueTypes[capabilityId]
      } else if (typeof defaultValue === 'boolean') {
        type = 'boolean'
      } else if (defaultValue !== null) {
        type = typeof defaultValue
      }
    }
    return type
  }

  const checkIfComplex = (capability) => {
    const type = getValueType(capability)
    return type === 'undefined' || type === 'object'
  }

  const checkIfBasicArray = (capability) => {
    return basicArraySupportedIds[capability?.capabilityId] !== undefined
  }

  return {
    supportedValues,
    basicArraySupportedIds,
    getValueType,
    checkIfComplex,
    checkIfBasicArray
  }
}
