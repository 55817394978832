<template>
  <div class="capabilities-menu">
    <pn-input
      class="filter-search"
      type="search"
      :disabled="loading"
      :placeholder="$t('CAPABILITIES.FILTER_CAPABILITY')"
      :value="draftFilter"
      @input="draftFilter = $event.target.value"
    />
    <div class="menu-list">
      <div v-if="isMobile() && menuItems.length">
        <pn-select
          class="full-width-on-mobile"
          :placeholder="$t('CAPABILITIES.DOMAINS')"
          :value="menuItems[0]"
          @selectchange="handleSelectChange"
        >
          <pn-option
            v-for="(option, index2) in menuItems"
            :key="index2"
            :value="option"
            >{{ getSentenceCase(option) }}</pn-option
          >
        </pn-select>
      </div>
      <div v-else>
        <aside v-if="loading" class="menu" aria-label="menu">
          <div v-for="index in 10" :key="index" class="menu-label">
            <div class="line shimmer"></div>
          </div>
        </aside>
        <aside v-else class="menu" aria-label="menu">
          <div
            v-for="(domain, index1) in menuItems"
            :key="index1"
            class="menu-label"
            :class="draftSelected === domain ? 'active' : ''"
            @click="handleSelectChange({ detail: domain })"
          >
            <a>
              <pn-icon
                v-show="checkIfPinned(domain)"
                :symbol="star"
                class="pin"
                color="orange400"
              />
              {{ getSentenceCase(domain) }}
            </a>
            <pn-icon :symbol="angle_right" small="true" color="gray200" />
          </div>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getSentenceCase } from '@/shared/utils/capabilityUtils.js'
import { usePinsStore } from '@/composables/usePinsStore.js'
import { useStoreSubscription } from '@/composables/useStoreSubscription.js'
import useMobileDetection from '@/shared/composables/useMobileDetection.js'
import { angle_right, star } from '@/shared/constants/icons.js'

export default {
  name: 'CapbilitiesSideMenu',
  props: {
    namespaces: Set,
    selected: {
      type: String,
      default() {
        return ''
      }
    },
    filter: {
      type: String,
      default() {
        return ''
      }
    },
    loading: Boolean
  },
  emits: ['didChangeSelection', 'didChangeFilter'],
  setup() {
    const { checkIfPinned } = usePinsStore()
    const { storeSubscription } = useStoreSubscription()
    const { isMobile } = useMobileDetection()
    return { checkIfPinned, storeSubscription, isMobile }
  },
  data() {
    return {
      draftSelected: '',
      draftFilter: '',
      menuItems: [],
      angle_right,
      star
    }
  },
  computed: {
    ...mapState('pins', ['pinnedItems'])
  },
  watch: {
    selected(val) {
      this.draftSelected = val
    },
    namespaces: {
      immediate: true,
      handler() {
        this.setupMenuItems()
      }
    },
    filter(val) {
      this.draftFilter = val
    },
    draftFilter(val) {
      this.$emit('didChangeFilter', val)
    }
  },
  mounted() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === 'pins/didUpdatePins') {
        this.setupMenuItems()
      }
    })
  },
  methods: {
    handleSelectChange(event) {
      this.draftSelected = event.detail
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.$emit('didChangeSelection', this.draftSelected)
    },
    sortedByPinnedItems(list) {
      const newList = [...list]
      return newList.sort((a, b) => {
        if (this.pinnedItems.has(a) && this.pinnedItems.has(b)) {
          return a.localeCompare(b)
        } else if (this.pinnedItems.has(a)) {
          return -1
        } else {
          return a.localeCompare(b)
        }
      })
    },
    setupMenuItems() {
      const list = this.namespaces ? Array.from(this.namespaces) : []
      this.menuItems = this.sortedByPinnedItems(list)
    },
    didChangeInput(event) {
      this.draftFilter = event?.target?.value
    },
    getSentenceCase
  }
}
</script>

<style scoped lang="scss">
.menu-label {
  padding: 1em 0.5em;
  border-bottom: solid 1px pn-styles.$gray50;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.menu-label a {
  color: pn-styles.$blue900;
  font-weight: 500;
  font-size: 90%;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.menu-label.active {
  border-left: solid 4px pn-styles.$blue400;
  background-color: pn-styles.$blue50;
}
.menu-label:hover {
  background-color: pn-styles.$blue50;
}
pn-select {
  width: 100%;
}
.filter-search {
  width: 100%;
  background-color: pn-styles.$white;
  position: sticky;
  top: 0;
}
.menu-list {
  overflow-y: scroll;
  height: 100vh;
}
</style>
