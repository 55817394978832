<template>
  <div>
    <RuleValueOnlyModal
      :rule="defaultModal.rule"
      :options="defaultModal.options"
      @did-save="handleSaveDefault"
      @did-close="closeModal(defaultModal)"
    />
    <RuleTypeIdModal
      :rule="typeIdModal.rule"
      :options="typeIdModal.options"
      @did-save="handleSaveTypeId"
      @did-remove="handleRemoveTypeId"
      @did-close="closeModal(typeIdModal)"
    />
    <RuleSourceIdModal
      :rule="sourceIdModal.rule"
      :options="sourceIdModal.options"
      @did-save="handleSaveSourceId"
      @did-remove="handleRemoveSourceId"
      @did-close="closeModal(sourceIdModal)"
    />
    <div class="capabilities-main-section">
      <NamespaceMenu
        :namespaces="namespaces"
        :loading="loading"
        :filter="options.filter"
        :selected="options.namespace"
        @did-change-selection="options.namespace = $event"
        @did-change-filter="options.filter = $event"
      />
      <div class="capabilities-content">
        <Loader v-if="loading" />
        <div v-if="filtered.length" class="capability-section">
          <div class="flex-group spaced-between">
            <PinnableTitle
              :title="options.namespace"
              :is-pinned="checkIfPinned(options.namespace)"
              @did-click-title="pin($event)"
            />
            <FoldControl :is-folded="foldAll" @did-toggle="foldAll = $event" />
          </div>
          <div class="capabilities-content-body">
            <CapabilityItem
              v-for="(item, index) in filtered"
              :key="index"
              :capability="item"
              :folded="foldAll"
              @will-add-type-id="handleWillAddTypeId"
              @will-update-type-id="handleWillUpdateTypeId"
              @will-add-source-id="handleWillAddSourceId"
              @will-update-source-id="handleWillUpdateSourceId"
              @will-update-default="handleWillUpdateDefault"
            />
          </div>
        </div>
        <EmptyContent v-else v-show="!loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import {
  getSentenceCase,
  getSubtitle,
  removedDoubleSpaceString
} from '@/shared/utils/capabilityUtils.js'
import { usePinsStore } from '@/composables/usePinsStore.js'
import { useCapabilityUtils } from '@/composables/useCapabilityUtils'
import { useStoreSubscription } from '@/composables/useStoreSubscription.js'
import { usePreloadCapabilities } from '@/composables/usePreloadCapabilities.js'
import useToast from '@/shared/composables/useToast'

import CapabilityItem from '@/components/Capabilities/CapabilityItem.vue'
import NamespaceMenu from '@/components/NamespaceMenu.vue'
import FoldControl from '@/components/Capabilities/FoldControl.vue'

import RuleValueOnlyModal from '../components/Modals/RuleValueOnlyModal.vue'
import RuleTypeIdModal from '../components/Modals/RuleTypeIdModal.vue'
import RuleSourceIdModal from '../components/Modals/RuleSourceIdModal.vue'
import PinnableTitle from '../components/PinnableTitle.vue'

const commonOptions = {
  show: false,
  title: '',
  allowRemove: false,
  allowSave: true,
  isLoading: false,
  capability: null
}

export default {
  name: 'Capbilities',
  components: {
    CapabilityItem,
    NamespaceMenu,
    FoldControl,
    RuleValueOnlyModal,
    RuleTypeIdModal,
    RuleSourceIdModal,
    PinnableTitle
  },
  setup() {
    usePreloadCapabilities()
    const { checkIfPinned, pin } = usePinsStore()
    const {
      namespaces,
      options,
      getFilteredByNamespace,
      setNamespaces,
      getAppliedFilters,
      getFilteredByQuery,
      filtered,
      foldAll,
      successToast
    } = useCapabilityUtils()
    const { showToaster } = useToast()
    const { storeSubscription } = useStoreSubscription()
    return {
      checkIfPinned,
      pin,
      namespaces,
      options,
      getFilteredByNamespace,
      setNamespaces,
      getAppliedFilters,
      getFilteredByQuery,
      filtered,
      foldAll,
      successToast,
      showToaster,
      storeSubscription
    }
  },
  data() {
    return {
      splitMapper: (item) => item.capabilityId.split('.')[0],
      defaultModal: {
        rule: {
          key: 'Default',
          value: ''
        },
        options: {
          ...commonOptions
        }
      },
      typeIdModal: {
        rule: {
          key: '',
          value: ''
        },
        options: {
          ...commonOptions,
          country: ''
        }
      },
      sourceIdModal: {
        rule: {
          key: '',
          value: ''
        },
        options: {
          ...commonOptions
        }
      }
    }
  },
  computed: {
    ...mapState('capability', ['capabilities', 'loading'])
  },
  watch: {
    capabilities(val) {
      this.filtered = val
      this.filtered = this.getAppliedFilters(this.filtered, this.splitMapper)
    },
    'options.filter': function (val) {
      const unfiltered = this.capabilities
      this.filtered = unfiltered
      this.filtered = this.getFilteredByQuery(
        this.filtered,
        val,
        this.splitMapper
      )
      this.setNamespaces(this.filtered, this.splitMapper)
      this.filtered = this.getFilteredByNamespace(
        this.capabilities,
        this.splitMapper
      )
    },
    'options.namespace': function () {
      this.filtered = this.getFilteredByNamespace(
        this.capabilities,
        this.splitMapper
      )
    }
  },
  mounted() {
    this.subscribeMutations()
  },
  methods: {
    ...mapActions('capability', [
      'getCapabilities',
      'updateCapability',
      'patchCapabilitySourceId'
    ]),
    getSentenceCase,
    subscribeMutations() {
      this.storeSubscription = this.$store.subscribe((mutation) => {
        if (mutation.type === 'capability/updateCapabilitySuccess') {
          this.showToaster(this.successToast)
          this.getCapabilities()
          this.closeAllModals()
        }
      })
    },
    checkMatrixHasProperty(matrix, row, col) {
      return matrix[row] !== undefined && matrix[row][col] !== undefined
    },
    handleWillAddTypeId(event) {
      this.typeIdModal.options.show = true
      this.typeIdModal.options.capability = event.capability
      this.typeIdModal.options.country = event.country
      this.typeIdModal.options.allowRemove = false
      this.typeIdModal.options.title = removedDoubleSpaceString(
        `Add capability ${getSentenceCase(
          getSubtitle(event.capability.capabilityId)
        )} for ${event.country}`
      )
    },
    handleWillUpdateTypeId(event) {
      this.typeIdModal.options.show = true
      this.typeIdModal.options.capability = event.capability
      this.typeIdModal.options.country = event.country
      this.typeIdModal.options.allowRemove = true
      this.typeIdModal.options.title = removedDoubleSpaceString(
        `Edit capability ${getSentenceCase(
          getSubtitle(event.capability.capabilityId)
        )} for ${event.country}`
      )
      this.typeIdModal.rule = event.rule
    },
    handleSaveTypeId(rule) {
      const payload = { ...this.typeIdModal.options.capability }
      const country = this.typeIdModal.options.country
      if (this.checkMatrixHasProperty(payload, country, rule.key)) {
        payload[country][rule.key] = rule.value
      } else {
        const newTypeId = {}
        newTypeId[rule.key] = rule.value
        payload[country] = { ...payload[country], ...newTypeId }
      }
      this.typeIdModal.options.isLoading = true
      this.updateCapability(payload)
    },
    handleRemoveTypeId(rule) {
      const payload = { ...this.typeIdModal.options.capability }
      const country = this.typeIdModal.options.country
      if (this.checkMatrixHasProperty(payload, country, rule.key)) {
        delete payload[country][rule.key]
      }
      this.typeIdModal.options.isLoading = true
      this.updateCapability(payload)
    },
    handleWillAddSourceId(event) {
      this.sourceIdModal.options.show = true
      this.sourceIdModal.options.capability = event.capability
      this.sourceIdModal.options.allowRemove = false
      this.sourceIdModal.options.title = removedDoubleSpaceString(
        `Add capability ${getSentenceCase(
          getSubtitle(event.capability.capabilityId)
        )} for servicepoint`
      )
    },
    handleWillUpdateSourceId(event) {
      this.sourceIdModal.options.show = true
      this.sourceIdModal.options.capability = event.capability
      this.sourceIdModal.options.allowRemove = true
      this.sourceIdModal.options.title = removedDoubleSpaceString(
        `Edit capability ${getSentenceCase(
          getSubtitle(event.capability.capabilityId)
        )} for ${event.rule.key}`
      )
      this.sourceIdModal.rule = event.rule
    },
    handleSaveSourceId(rule) {
      const payload = { ...this.sourceIdModal.options.capability }
      this.sourceIdModal.options.isLoading = true
      if (this.checkMatrixHasProperty(payload, 'sourceIds', rule.key)) {
        const params = {
          capabilityId: payload.capabilityId,
          sourceId: rule.key,
          value: rule.value
        }
        this.patchCapabilitySourceId(params)
      } else {
        const newSourceId = {}
        newSourceId[rule.key] = rule.value
        payload.sourceIds = { ...payload.sourceIds, ...newSourceId }
        this.updateCapability(payload)
      }
    },
    handleRemoveSourceId(rule) {
      const payload = { ...this.sourceIdModal.options.capability }
      if (this.checkMatrixHasProperty(payload, 'sourceIds', rule.key)) {
        delete payload.sourceIds[`${rule.key}`]
      }
      this.sourceIdModal.options.isLoading = true
      this.updateCapability(payload)
    },
    handleWillUpdateDefault(event) {
      const rule = {
        key: 'Default',
        value: event.capability.default
      }
      this.defaultModal.options.show = true
      this.defaultModal.options.capability = event.capability
      this.defaultModal.options.title = removedDoubleSpaceString(
        getSentenceCase(
          `Edit Default ${getSubtitle(event.capability.capabilityId)}`
        )
      )
      this.defaultModal.rule = rule
    },
    handleSaveDefault(rule) {
      const payload = { ...this.defaultModal.options.capability }
      payload.default = rule.value
      this.defaultModal.options.isLoading = true
      this.updateCapability(payload)
    },
    closeModal(modalData) {
      modalData.options.show = false
      modalData.rule = { key: '', value: '' }
      modalData.options.isLoading = false
    },
    closeAllModals() {
      this.closeModal(this.defaultModal)
      this.closeModal(this.typeIdModal)
      this.closeModal(this.sourceIdModal)
    }
  }
}
</script>
