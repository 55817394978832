import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_RETAIL_PORTAL_API}`
})

serviceInstance.interceptors.request.use((request) => {
  request.headers['Content-type'] = 'application/json; charset=utf-8'
  request.headers.Accept = 'application/json; charset=utf-8'
  request.headers['x-bap-key'] = process.env.VUE_APP_BAP_KEY
  request.headers.Authorization = pnTopbar.access_token
  return request
})

export default {
  getPermissions: async () => {
    return serviceInstance.get('/permissions')
  }
}
