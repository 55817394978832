<template>
  <pn-modal class="rule-editor-modal" :open="options.show" @close="close">
    <div class="pn-modal-content">
      <h3 class="modal-card-title">
        <pn-icon :symbol="edit" small="false" color="black" />
        {{ options.title }}
      </h3>
      <section class="modal-card-body">
        <div
          class="rule-controls"
          :class="isDeleting ? 'rule-controls--faded' : ''"
        >
          <slot :is-deleting="isDeleting"></slot>
        </div>
        <pn-toast
          v-if="error"
          :icon="alert_info_circle"
          class="error-feedback"
          closable="false"
        >
          {{ $t(error) }}
        </pn-toast>
        <RuleRemoveControl
          v-if="options.show && options.allowRemove && !options.isLoading"
          @did-remove="remove"
          @did-change="isDeleting = $event"
        />
      </section>
      <footer class="modal-card-foot">
        <div>
          <pn-button
            v-show="!options.isLoading"
            appearance="light"
            @click="close()"
          >
            {{ $t('APP.CLOSE') }}
          </pn-button>
        </div>
        <div>
          <pn-button
            v-show="isSaveEnabled || options.isLoading"
            :key="isSaveEnabled"
            :loading="options.isLoading"
            @click="save()"
          >
            {{ $t('APP.SAVE') }}
          </pn-button>
        </div>
      </footer>
    </div>
  </pn-modal>
</template>

<script>
import { alert_info_circle, edit } from '@/shared/constants/icons.js'
import RuleRemoveControl from './ui/RuleRemoveControl.vue'

export default {
  name: 'BaseRuleModal',
  components: {
    RuleRemoveControl
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          title: '',
          show: false,
          allowRemove: false,
          isLoading: false
        }
      }
    },
    error: {
      type: String,
      default: ''
    },
    isSaveEnabled: Boolean
  },
  emits: ['didSave', 'didClose', 'didRemove', 'didToggleDeleting'],
  data() {
    return {
      isDeleting: false,
      alert_info_circle,
      edit
    }
  },
  watch: {
    isDeleting(val) {
      this.$emit('didToggleDeleting', val)
    }
  },
  methods: {
    save() {
      this.$emit('didSave')
      this.isDeleting = false
    },
    close() {
      this.$emit('didClose')
      this.isDeleting = false
    },
    remove() {
      this.$emit('didRemove')
      this.isDeleting = false
    }
  }
}
</script>

<style lang="scss" scoped>
.rule-controls {
  &--faded {
    opacity: 0.3;
  }

  ::v-deep(.pn-input) {
    width: 100%;
  }
}
</style>
