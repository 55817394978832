const getPinnedCapabilities = () => {
  const items = localStorage.getItem('pinned')
  if (items) {
    return JSON.parse(items)
  }
  return []
}

export default {
  namespaced: true,
  state: {
    pinnedItems: new Set(getPinnedCapabilities() || [])
  },
  actions: {
    pin: ({ rootState, commit }, key) => {
      const pinned = getPinnedCapabilities() || []
      rootState.pins.pinnedItems = new Set(pinned)

      if (rootState.pins.pinnedItems.has(key)) {
        rootState.pins.pinnedItems.delete(key)

        const filtered = pinned.filter((query) => query !== key)
        localStorage.setItem('pinned', JSON.stringify(filtered))
      } else {
        rootState.pins.pinnedItems.add(key)
        const found = pinned.some((query) => query === key)
        if (!found) {
          pinned.push(key)
        }
        localStorage.setItem('pinned', JSON.stringify(pinned))
      }
      commit('didUpdatePins')
    },
    clearPins: ({ rootState, commit }) => {
      rootState.pins.pinnedItems = new Set()
      localStorage.removeItem('pinned')
      commit('didClearPins')
    }
  },
  mutations: {
    didClearPins() {
      // Do nothing, just for notification
    },
    didUpdatePins() {
      // Do nothing, just for notification
    }
  }
}
