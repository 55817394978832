<template>
  <div v-if="capability" class="capability">
    <div class="capability-title" @click.stop="isFolded = !isFolded">
      <h4 class="capability-header">
        <span class="rule-key">{{ title }}</span>
        <span
          v-show="isFolded"
          class="rule-value tooltip"
          data-tooltip="Default Value"
          @click.stop="editDefault"
        >
          {{ defaultDisplay }}
        </span>
      </h4>
      <pn-icon :symbol="isFolded ? angle_small_down : angle_small_up" />
    </div>
    <div v-show="!isFolded" class="capability-item-table">
      <p v-if="capability.description">{{ capability.description }}</p>
      <div class="flex-group row">
        <div class="default-value">
          <span class="text">
            <span class="rule-key">{{ $t('CAPABILITIES.DEFAULT') }}:</span>
            <span class="rule-value" @click.stop="editDefault">
              {{ defaultDisplay }}
            </span>
          </span>
        </div>
        <div class="contexts">
          <span class="rule-key">Context:</span>
          <span
            v-for="(context, index1) in capability.context"
            :key="index1"
            class="tag"
          >
            {{ context }}
          </span>
        </div>
      </div>
      <TypeIdList
        v-for="(country, index) in countries"
        :key="index"
        class="capability-item-section"
        :capability="draft"
        :data="draft[`${country}`]"
        :country-code="country"
        @will-add-rule="addTypeId"
        @will-edit-rule="editTypeId"
      />
      <SourceIdList
        class="capability-item-section"
        :data="sourceIds"
        :allow-add="true"
        @will-edit-keypair="editSourceId"
        @will-add-keypair="addSourceId"
      />
    </div>
  </div>
</template>

<script>
import { getSentenceCase } from '@/shared/utils/capabilityUtils.js'

import { getSubtitle } from '@/shared/utils/capabilityUtils'
import TypeIdList from './TypeIdList.vue'
import SourceIdList from './SourceIdList.vue'
import { angle_small_down, angle_small_up } from '@/shared/constants/icons.js'

export default {
  name: 'CapbilityItem',
  components: {
    TypeIdList,
    SourceIdList
  },
  props: {
    capability: {
      type: Object,
      default() {
        return null
      }
    },
    folded: Boolean
  },
  emits: [
    'willAddTypeId',
    'willUpdateTypeId',
    'willAddSourceId',
    'willUpdateSourceId',
    'willUpdateDefault'
  ],
  data() {
    return {
      isFolded: true,
      draft: {},
      countries: ['SE', 'NO', 'DK', 'FI'],
      angle_small_down,
      angle_small_up
    }
  },
  computed: {
    title() {
      return getSentenceCase(getSubtitle(this.capability?.capabilityId))
    },
    sourceIds() {
      return this.capability?.sourceIds || {}
    },
    defaultDisplay() {
      return this.capability?.default !== null &&
        this.capability?.default !== ''
        ? this.capability?.default
        : 'N/A'
    }
  },
  watch: {
    folded: {
      immediate: true,
      handler(val) {
        this.isFolded = val
      }
    },
    capability: {
      immediate: true,
      handler(val) {
        this.draft = val
        if (this.draft) {
          for (const countryCode of this.countries) {
            if (this.draft[countryCode] === undefined) {
              this.draft[countryCode] = {}
            }
          }
        }
      }
    }
  },
  methods: {
    addTypeId(event) {
      this.$emit('willAddTypeId', {
        capability: this.capability,
        country: event.country
      })
    },
    editTypeId(event) {
      this.$emit('willUpdateTypeId', {
        capability: this.capability,
        country: event.country,
        rule: event.rule
      })
    },
    addSourceId() {
      this.$emit('willAddSourceId', {
        capability: this.capability
      })
    },
    editSourceId(event) {
      this.$emit('willUpdateSourceId', {
        capability: this.capability,
        rule: event
      })
    },
    editDefault() {
      this.$emit('willUpdateDefault', {
        capability: this.capability
      })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  max-width: 80%;
}
.default-value .rule-value {
  cursor: pointer;
}
.tag {
  width: fit-content;
  border-radius: 4px;
  text-align: center;
  padding: 1px 6px;
  margin: 0 3px;
  background: pn-styles.$gray200;
}
</style>
