<template>
  <div class="search-metadata">
    <ul class="breadcrumb">
      <li>
        <router-link to="/">{{
          `${$t('CAPABILITIES.ALL')} ${$t('CAPABILITIES.TITLE')}`
        }}</router-link>
      </li>
      <li>
        <span class="active">{{ title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['didClickAllCapabilities'],
  methods: {
    reset() {
      this.$emit('didClickAllCapabilities')
    }
  }
}
</script>

<style scoped>
.search-metadata {
  padding: 0.5em 0;
}
.active {
  font-size: x-large;
}
</style>
