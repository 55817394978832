import BaseRuleModal from '../BaseRuleModal.vue'
import RuleValueControl from '../ui/RuleValueControl.vue'

export default {
  components: {
    BaseRuleModal,
    RuleValueControl
  },
  props: {
    rule: {
      type: Object,
      default() {
        return {
          key: '',
          value: ''
        }
      }
    },
    options: {
      type: Object,
      default: null
    }
  },
  computed: {
    isComplex() {
      return (
        this.checkIfComplex(this.options?.capability) &&
        !this.checkIfBasicArray(this.options?.capability)
      )
    }
  },
  watch: {
    isComplex(val) {
      if (val) {
        this.error = 'CAPABILITIES.UNSUPPORTED_VALUE'
      }
    }
  },
  methods: {
    handleValueValidation(isValid) {
      this.error = isValid ? '' : 'CAPABILITIES.INPUT_VALUE'
    },
    handleToggleDeletion(val) {
      if (val) {
        this.draft.key = this.rule.key
        this.draft.value = this.rule.value
      }
    }
  }
}
