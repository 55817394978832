import { reactive, onUnmounted } from 'vue'

export function useStoreSubscription() {
  const storeSubscription = reactive({})

  onUnmounted(() => {
    if (typeof storeSubscription === 'function') {
      storeSubscription()
    }
  })

  return { storeSubscription }
}
