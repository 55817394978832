import axios from 'axios'

const serviceInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/capability`
})

serviceInstance.interceptors.request.use((request) => {
  request.headers['Content-type'] = 'application/json; charset=utf-8'
  request.headers.Accept = 'application/json; charset=utf-8'
  request.headers.Authorization = pnTopbar.access_token
  request.headers['x-bap-key'] = process.env.VUE_APP_BAP_KEY
  return request
})

export default {
  getCapabilities: async () => {
    return serviceInstance.get()
  },
  getCapabilitiesOf: async (ssid) => {
    return serviceInstance.get(`/${ssid}`)
  },
  updateCapability: async (payload) => {
    return serviceInstance.put('', payload)
  },
  patchCapabilitySourceId: async (capabilityId, sourceId, value) => {
    return serviceInstance.patch(`/${sourceId}/${capabilityId}`, {
      value
    })
  }
}
