import { createRouter as _createRouter, createWebHistory } from 'vue-router'
import Capabilities from '@/views/Capabilities.vue'
import Servicepoint from '@/views/Servicepoint.vue'

export function createRouter() {
  return _createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: () => ({ top: 0 }),
    routes: [
      {
        path: '/servicepoints/:id?',
        name: 'Servicepoint',
        component: Servicepoint
      },
      {
        path: '/',
        name: 'Capabilities',
        component: Capabilities
      },
      { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
  })
}
