<template>
  <div v-if="isActive" class="content">
    <div v-if="isBoolean" class="toggle-switch">
      <pn-toggle-switch
        toggleid="rule-value-switch"
        :checked="cleanedValue"
        :disabled="disabled"
        @click.prevent="toggle"
      ></pn-toggle-switch>
      <span class="label1">
        {{ `${cleanedValue}` }}
      </span>
    </div>

    <pn-input
      v-if="isNumber || isString"
      class="full-width"
      :label="$t('CAPABILITIES.VALUE')"
      :invalid="!isValid"
      :value="cleanedValue"
      :type="isNumber ? 'number' : 'text'"
      :disabled="disabled"
      @input="draft = $event.target.value"
    />

    <SimpleArrayControl
      v-if="isBasicArray"
      class="full-width"
      :capability-id="capability ? capability.capabilityId : ''"
      :items="cleanedValue"
      :value-type="basicArrayValueType"
      :disabled="disabled"
      @did-update-items="draft = $event"
    />

    <JsonViewer
      v-if="isComplex"
      class="json-view full-width"
      :value="cleanedValue"
    />
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
import SimpleArrayControl from './SimpleArrayControl.vue'
import { useValueTypeUtils } from '@/composables/useValueTypeUtils.js'
import { hasDifferences } from '@/shared/utils/capabilityUtils.js'

export default {
  name: 'RuleValueControl',
  components: {
    JsonViewer,
    SimpleArrayControl
  },
  props: {
    capability: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    disabled: Boolean,
    isActive: Boolean
  },
  emits: ['didChange', 'didValidate'],
  setup() {
    const {
      getValueType,
      checkIfComplex,
      checkIfBasicArray,
      basicArraySupportedIds
    } = useValueTypeUtils()
    return {
      getValueType,
      checkIfComplex,
      checkIfBasicArray,
      basicArraySupportedIds
    }
  },
  data() {
    return {
      draft: ''
    }
  },
  computed: {
    valueType() {
      return this.getValueType(this.capability)
    },
    basicArrayValueType() {
      return (
        this.basicArraySupportedIds[this.capability?.capabilityId] || 'String'
      )
    },
    isValid() {
      if (this.isBoolean) {
        return true
      } else if (this.isBasicArray) {
        return this.draft.length
      } else {
        return /\S/.test(this.draft)
      }
    },
    isBoolean() {
      return this.valueType === 'boolean'
    },
    isNumber() {
      return this.valueType === 'number'
    },
    isString() {
      return this.valueType === 'string'
    },
    isBasicArray() {
      return this.checkIfBasicArray(this.capability)
    },
    cleanedValue() {
      let finalValue = this.draft
      if (this.isNumber) {
        finalValue = this.draft * 1
      } else if (this.isBoolean) {
        finalValue = Boolean(this.draft)
      }
      return finalValue
    },
    isComplex() {
      return this.checkIfComplex(this.capability) && !this.isBasicArray
    }
  },
  watch: {
    value(val) {
      if (this.isBasicArray) {
        if (val && Array.isArray(val)) {
          this.draft = [...val]
        } else {
          this.draft = []
        }
      } else {
        this.draft = val
      }
    },
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit('didValidate', val)
      }
    },
    cleanedValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (hasDifferences(newVal, oldVal)) {
          this.$emit('didChange', newVal)
        }
      }
    },
    capability(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('didChange', this.cleanedValue)
      }
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.draft = !this.draft
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1em;
}
.json-view {
  resize: vertical;
  overflow-y: scroll;
  background-color: pn-styles.$gray50;
  min-width: 500px;
}

.toggle-switch {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 4px;
}
</style>
