import { sentenceCase } from '@postnord/retail-backoffice-components/dist'

export const getSentenceCase = (value) => {
  return sentenceCase(value).replace(/Post Nord/g, 'PostNord')
}

/**
 * Returns a simple readable title for a capability Id
 * @param {*} value - a string value usually a capabilityId value
 * @returns - simple capability title
 */
export const getSubtitle = (value) => {
  let title = ''
  const splitted = value.split('.')
  splitted.shift()
  splitted.map((subtitle) => {
    title += `${getSentenceCase(subtitle)} `
  })
  title = title.replace(/ /g, '')

  return title
}

/**
 * Convenient function to check if an object is valid and has properties in it
 * @param {*} object
 * @returns Boolean
 */
export const isObjectWithValue = (object) =>
  object && Object.keys(object).length

/**
 * Returns a transformed capability for the backend to consume. After the capability
 * is mapped, the structure was changed, so use this method when updating a capability
 * back to the API
 * @param {*} capability
 * @returns
 */
export const transformToCapability = (capability) => {
  const transformed = {}
  transformed.capabilityId = capability.capabilityId
  transformed.default = capability.default

  if (isObjectWithValue(capability['SE'])) {
    transformed['SE'] = capability['SE']
  }
  if (isObjectWithValue(capability['DK'])) {
    transformed['DK'] = capability['DK']
  }
  if (isObjectWithValue(capability['NO'])) {
    transformed['NO'] = capability['NO']
  }
  if (isObjectWithValue(capability['FI'])) {
    transformed['FI'] = capability['FI']
  }

  if (capability.sourceIds) {
    const sourceIds = {}
    capability.sourceIds.map((aSourceId) => {
      sourceIds[aSourceId.key] = aSourceId.value
    })
    transformed.sourceIds = sourceIds
  }

  if (capability.countries) {
    capability.countries.forEach((country) => {
      const countryObject = {}
      Object.keys(country).forEach((key) => {
        if (key !== 'countryCode') {
          countryObject[key] = country[key]
        }
      })
      transformed[country.countryCode] = countryObject
    })
  }

  if (capability.context) {
    transformed.context = Array.from(capability.context)
  }

  if (capability.description) {
    transformed.description = capability.description
  }

  return transformed
}

/**
 * Returns a boolean true if the value is a valid formatted SSID
 * @param {*} val - a string SSID value
 * @returns - Boolean
 */
export const isValidSsid = (val) => {
  const matchValue = String(val).match(/(SE|DK|FI|NO)\d{3,}/gim)
  return matchValue !== null && matchValue[0] === val
}

/**
 * Creates a payload ready for backend to consume when updating a capability
 * @param {*} payload - the unedited capability payload
 * @param {*} type - if typeId change or sourceId change
 * @param {*} itemToAdd - the object to add into the capability
 * @returns - a new constructed payload to send to the backend
 */
export const addedChangesToPayload = (payload, type, itemToAdd) => {
  const newPayload = { ...payload }
  if (type === 'country') {
    if (!newPayload[itemToAdd.country]) {
      newPayload[itemToAdd.country] = {}
    }
    newPayload[itemToAdd.country][itemToAdd.rule.typeId] = itemToAdd.rule.value
  } else if (type === 'sourceId') {
    newPayload.sourceIds[itemToAdd.rule.typeId.toUpperCase()] =
      itemToAdd.rule.value
  }
  return newPayload
}

/**
 * Creates a new string from a source string without the double space
 * @param {*} value - the source string
 * @returns - a new string without the double space in string
 */
export const removedDoubleSpaceString = (value) => {
  return value?.trim().replace(/ {2}/g, ' ') || value
}

export const hasDifferences = (valueA, valueB) => {
  let a = valueA
  let b = valueB
  if (Array.isArray(a) && Array.isArray(b)) {
    a = JSON.stringify(valueA)
    b = JSON.stringify(valueB)
  }
  return a !== b
}
