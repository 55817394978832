<template>
  <div class="capability-section">
    <PinnableTitle
      :title="title"
      :is-pinned="checkIfPinned(title)"
      @did-click-title="pin($event)"
    />
    <div v-if="item" class="capabilities-content-body">
      <div
        v-for="(prop, index) in Object.keys(item)"
        :key="index"
        class="capability"
      >
        <div class="capability-title">
          <h4 class="capability-header" @click="editRule(prop)">
            <span class="rule-key">{{ getSentenceCase(prop) }}:</span>
            <span class="rule-value">{{ item[prop] }}</span>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSentenceCase } from '@/shared/utils/capabilityUtils.js'
import { usePinsStore } from '@/composables/usePinsStore.js'
import PinnableTitle from '../PinnableTitle.vue'

export default {
  name: 'CapabilityItem',
  components: {
    PinnableTitle
  },
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    item: {
      type: String,
      default() {
        return null
      }
    }
  },
  emits: ['willUpdateRule'],
  setup() {
    const { checkIfPinned, pin } = usePinsStore()
    return { checkIfPinned, pin }
  },
  data() {
    return {
      isFolded: true
    }
  },
  methods: {
    getSentenceCase,
    editRule(prop) {
      const rule = {
        key: prop,
        value: this.item[prop]
      }
      this.$emit('willUpdateRule', {
        capability: this.item,
        rule: rule
      })
    }
  }
}
</script>
