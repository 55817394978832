<template>
  <div class="danger-zone">
    <pn-button
      v-show="!isDeleting"
      small
      appearance="warning"
      :icon="trash_can"
      @click="isDeleting = true"
    >
      Remove this Rule
    </pn-button>
    <pn-button
      v-show="isDeleting"
      small
      appearance="warning"
      :icon="trash_can"
      @click="handleRemove"
    >
      Confirm Remove
    </pn-button>
    <pn-button
      v-show="isDeleting"
      small
      appearance="light"
      @click="isDeleting = false"
    >
      {{ $t('APP.CANCEL') }}
    </pn-button>
  </div>
</template>

<script>
import { trash_can } from '@/shared/constants/icons.js'

export default {
  name: 'RuleRemoveControl',
  emits: ['didChange', 'didRemove'],
  data() {
    return {
      isDeleting: false,
      trash_can
    }
  },
  watch: {
    isDeleting(val) {
      this.$emit('didChange', val)
    }
  },
  methods: {
    handleRemove() {
      this.$emit('didRemove')
    }
  }
}
</script>

<style lang="scss" scoped>
.danger-zone {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  padding-top: 1em;
  pn-button {
    margin-right: 4px;
  }
}
</style>
