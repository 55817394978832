<template>
  <div class="content">
    <div
      v-for="(item, index) in draftItems"
      :key="index"
      class="flex-group spaced-between"
    >
      <pn-input
        label=""
        :value="draftItems[index]"
        :type="valueType === 'Number' ? 'number' : 'text'"
        disabled
      />

      <pn-button
        small
        appearance="warning"
        icon-only
        :icon="trash_can"
        @click="remove(item)"
      >
        {{ $t('APP.REMOVE') }}
      </pn-button>
    </div>
    <form @submit.prevent="add">
      <div class="flex-group spaced-between add-form">
        <pn-input
          label="Add Value:"
          :value="draft"
          :type="valueType === 'Number' ? 'number' : 'text'"
          :disabled="disabled"
          @input="draft = $event.target.value"
        />

        <pn-button
          v-show="isValidToAdd"
          small
          icon-only
          :icon="plus"
          @click="add"
        >
          {{ $t('APP.ADD') }}
        </pn-button>
      </div>
      <transition name="slide">
        <pn-toast
          v-show="showInvalidMessage"
          :icon="alert_info_circle"
          appearance="warning"
          class="error-feedback"
          closable="false"
        >
          <p>Duplicate Value</p>
        </pn-toast>
      </transition>
    </form>
  </div>
</template>

<script>
import { alert_info_circle, trash_can, plus } from '@/shared/constants/icons.js'

export default {
  name: 'SimpleArrayControl',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    valueType: {
      type: String,
      default: 'String'
    },
    disabled: Boolean
  },
  emits: ['didUpdateItems'],
  data() {
    return {
      draftItems: [],
      draft: '',
      showInvalidMessage: false,
      alert_info_circle,
      trash_can,
      plus
    }
  },
  computed: {
    isValidToAdd() {
      return /\S/.test(this.draft)
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.draftItems = [...val]
        } else {
          this.draftItems = []
          this.draft = ''
          this.showInvalidMessage = false
        }
      }
    }
  },
  methods: {
    remove(item) {
      this.draftItems = this.draftItems.filter((anItem) => anItem !== item)
      this.$emit('didUpdateItems', this.draftItems)
    },
    add() {
      if (this.isValidToAdd && !this.disabled) {
        if (this.valueType === 'Number') {
          this.draft = this.draft * 1
        }
        const filtered = this.draftItems.filter((item) => item === this.draft)
        if (filtered.length) {
          this.showInvalidMessage = true
        } else {
          this.showInvalidMessage = false

          if (this.valueType === 'Number' || !isNaN(this.draft)) {
            this.draft = parseInt(this.draft)
          }
          this.draftItems = [...this.draftItems, this.draft]
          this.draft = ''
          this.$emit('didUpdateItems', this.draftItems)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 1em 0;

  pn-input {
    display: unset;
    width: 80% !important;
  }
}
.flex-group {
  align-items: baseline !important;
}
.flex-group.add-form {
  align-items: center !important;
}
.add-form {
  padding-top: 1.5em;
  border-top: solid 1px pn-styles.$gray200;
}
</style>
