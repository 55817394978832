<template>
  <div
    v-if="data"
    class="country-card source-id-list"
    :class="isMaximized ? 'maximize-width' : ''"
    @dblclick.stop="maximize"
  >
    <span class="title">
      <pn-select
        :value="selectedCountry"
        class="source-ids"
        @selectchange="handleSelectChange"
        @dblclick="$event.stopPropagation()"
      >
        <pn-option value="ALL">Source IDs</pn-option>
        <pn-option
          v-for="(country, index) in countries"
          :key="index"
          :value="country"
        >
          <span
            class="flag-icon"
            :class="`flag-icon-${country.toLowerCase()}`"
          ></span>
          {{ country }}
        </pn-option>
      </pn-select>
      <pn-search-field
        v-show="isMaximized"
        button="none"
        placeholder="Search Source ID"
        :value="filterId"
        @update="filterId = $event.target.value"
      ></pn-search-field>
    </span>

    <div class="rule-set" :class="currentStyles">
      <div v-for="(keypair, index) in keypairs" :key="index" class="rule-item">
        <span class="text" @click="edit(keypair)"
          ><span class="rule-key">{{ keypair.key }}</span
          >:<span class="rule-value">{{ keypair.value }}</span></span
        >
      </div>
    </div>
    <div v-show="allowAdd || allowExpanding" class="actions">
      <pn-button
        v-show="allowAdd"
        small
        tooltip
        icon-only
        :icon="plus"
        @click="add()"
      >
        {{ $t('APP.ADD') }}
      </pn-button>
      <pn-button
        v-show="allowExpanding && keypairs.length >= 10"
        :key="isExpanded"
        appearance="light"
        small
        tooltip
        icon-only
        :icon="isExpanded ? minus : expandIcon"
        @click="expand"
      >
        {{ isExpanded ? $t('APP.MINIMIZE') : $t('APP.EXPAND') }}
      </pn-button>
    </div>
  </div>
</template>

<script>
import { isObject } from '@postnord/retail-backoffice-components/dist'
import { plus, minus, expand as expandIcon } from '@/shared/constants/icons.js'

export default {
  name: 'SourceIdList',
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    data: {
      type: Object,
      default() {
        return null
      }
    },
    allowEdit: {
      type: Boolean,
      default() {
        return true
      }
    },
    allowAdd: {
      type: Boolean,
      default() {
        return false
      }
    },
    allowExpanding: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  emits: ['willEditKeypair', 'willAddKeypair', 'isViewExpanded'],
  data() {
    return {
      isExpanded: false,
      isMaximized: false,
      selectedCountry: 'ALL',
      countries: ['SE', 'DK', 'FI', 'NO'],
      filterId: '',
      plus,
      minus,
      expandIcon
    }
  },
  computed: {
    keypairs() {
      let keypairs = []
      Object.keys(this.data).forEach((prop) => {
        if (isObject(this.data[prop])) {
          const innerData = this.data[prop]
          Object.keys(innerData).forEach((subProp) => {
            const keypair = {
              key: `${prop}.${subProp}`,
              value: innerData[subProp]
            }
            keypairs.push(keypair)
          })
        } else {
          const keypair = {
            key: prop,
            value: this.data[prop]
          }
          keypairs.push(keypair)
        }
      })
      if (this.selectedCountry !== 'ALL') {
        keypairs = keypairs.filter((item) =>
          item.key.startsWith(this.selectedCountry)
        )
      }
      if (this.filterId) {
        keypairs = keypairs.filter((item) => item.key.includes(this.filterId))
      }
      keypairs.sort((a, b) => (a.key < b.key ? -1 : 1))

      return keypairs
    },
    currentStyles() {
      return this.allowExpanding && this.isExpanded ? 'expanded' : ''
    }
  },
  methods: {
    edit(keypair) {
      this.$emit('willEditKeypair', keypair)
    },
    add() {
      this.$emit('willAddKeypair')
    },
    expand() {
      this.$emit('isViewExpanded', this.isExpanded)
      this.isExpanded = !this.isExpanded
    },
    handleSelectChange(event) {
      this.selectedCountry = event.detail
    },
    maximize() {
      this.isMaximized = !this.isMaximized
    }
  }
}
</script>

<style lang="scss" scoped>
.flag-icon {
  margin: 0 4px;
}

.title {
  display: flex !important;
  justify-content: space-between;

  pn-select {
    min-width: 200px;
  }
}
</style>
