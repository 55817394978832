<template>
  <div class="domain-header">
    <div class="domain-title">
      <pn-icon
        :symbol="star"
        class="pin"
        :color="isPinned ? 'orange400' : 'black'"
        @click="pin(title)"
      />
      <h2>
        {{ title ? getSentenceCase(title) : '' }}
      </h2>
    </div>
  </div>
</template>

<script>
import { star } from '@/shared/constants/icons.js'
import { getSentenceCase } from '@/shared/utils/capabilityUtils.js'

export default {
  name: 'PinnableTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    isPinned: Boolean
  },
  emits: ['didClickTitle'],
  data() {
    return {
      star
    }
  },
  methods: {
    getSentenceCase,
    pin() {
      this.$emit('didClickTitle', this.title)
    }
  }
}
</script>
