import i18n from '@/i18n/i18n'
import { getTokenExpires } from '@postnord/retail-backoffice-components/dist'

export default {
  state: {
    tokenExpires: 0,
    cookies: {}
  },
  getters: {
    isLoggedIn: () => {
      if (pnTopbar && pnTopbar.user) {
        return pnTopbar.isLoggedIn
      }

      return false
    },
    userId: () => {
      if (pnTopbar && pnTopbar.user) {
        return pnTopbar.user.userid
      }

      return null
    }
  },
  actions: {
    setLocalizationState({ rootState }) {
      rootState.lang = pnTopbar.language || 'en'
      rootState.market = pnTopbar.market || 'EN'
      rootState.userType = pnTopbar.user ? pnTopbar.user.user_type_id : 'public'

      pnTopbar.onChangeLanguage = (lang) => {
        rootState.lang = lang
        i18n.global.locale.value = lang
      }

      pnTopbar.onChangeMarket = (market) => {
        rootState.market = market
      }

      pnTopbar.onLogout = () => {
        pnTopbar.login()
      }
    },
    setAccessToken({ rootState }) {
      rootState.accessToken = pnTopbar.access_token || 'anonymous'
    },
    setTokenExpires({ rootState }) {
      rootState.topbar.tokenExpires = getTokenExpires(rootState.topbar.cookies)

      setTimeout(() => {
        rootState.error = 'Token has expired. Please reload the page.'
        rootState.accessToken = pnTopbar.access_token
      }, rootState.topbar.tokenExpires)
    }
  }
}
