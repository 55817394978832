<template>
  <div
    v-if="data"
    class="country-card"
    :class="isMaximized ? 'maximize-width' : ''"
    @dblclick.stop="maximize"
  >
    <span class="title"
      >{{ countryCode }}
      <span
        v-if="countryCode"
        class="flag-icon"
        :class="`flag-icon-${countryCode.toLowerCase()}`"
      ></span>
    </span>
    <div class="rule-set">
      <div v-for="(rule, index) in rules" :key="index" class="rule-item">
        <span class="text" @click="edit(rule)">
          <span class="rule-key">{{ rule.key }}</span
          >:<span class="rule-value">{{ rule.value }}</span>
        </span>
      </div>
    </div>
    <div class="actions">
      <pn-button small tooltip icon-only :icon="plus" @click="add()">
        {{ $t('APP.ADD') }}
      </pn-button>
    </div>
  </div>
</template>

<script>
import { plus } from '@/shared/constants/icons.js'

export default {
  name: 'TypeIdList',
  props: {
    capability: {
      type: Object,
      default() {
        return null
      }
    },
    data: {
      type: Object,
      default() {
        return null
      }
    },
    countryCode: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['willAddRule', 'willEditRule'],
  data() {
    return {
      isExpanded: false,
      isMaximized: false,
      plus
    }
  },
  computed: {
    rules() {
      const rules = []
      Object.keys(this.data).forEach((prop) => {
        const rule = {
          key: prop,
          value: this.data[prop]
        }
        rules.push(rule)
      })
      return rules
    }
  },
  methods: {
    add() {
      this.$emit('willAddRule', {
        capability: this.capability,
        country: this.countryCode,
        data: this.data
      })
    },
    edit(rule) {
      this.$emit('willEditRule', {
        capability: this.capability,
        country: this.countryCode,
        data: this.data,
        rule: rule
      })
    },
    maximize() {
      this.isMaximized = !this.isMaximized
    }
  }
}
</script>
