import { alert_exclamation_circle } from '@/shared/constants/icons.js'

const ICON_ALERT_EXCLAMATION_CIRCLE = alert_exclamation_circle

export const handleError = (dispatch, error) => {
  const toast = {
    closable: true,
    icon: ICON_ALERT_EXCLAMATION_CIRCLE,
    appearance: 'warning',
    message: error,
    hidden: false
  }
  dispatch('toaster/showToaster', toast, { root: true })
}

export default {
  namespaced: true,
  state: {
    closable: true,
    icon: ICON_ALERT_EXCLAMATION_CIRCLE,
    appearance: '',
    message: '',
    hidden: true,
    showTimer: null
  },
  actions: {
    showToaster({ rootState, commit }, data) {
      commit('resetState', data)
      commit('didShowToaster', data)
      if (rootState.toaster.showTimer) {
        clearTimeout(rootState.toaster.showTimer)
      }
      rootState.toaster.showTimer = setTimeout(
        () => {
          rootState.toaster.hidden = true
        },
        data.seconds ? data.seconds : 3000
      )
    },
    hideToaster({ rootState }) {
      rootState.toaster.hidden = true
    }
  },
  mutations: {
    didShowToaster(state, data) {
      state.hidden = false
      state.icon = data.icon
      state.appearance = data.appearance
      state.message = data.message
      state.closable = data.closable
    },
    resetState(state) {
      state.icon = ICON_ALERT_EXCLAMATION_CIRCLE
      state.closable = false
      state.appearance = ''
      state.message = ''
    }
  }
}
