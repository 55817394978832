import { createLogger, createStore } from 'vuex'

import topbar from './modules/topbar'
import capability from './modules/capability'
import pins from './modules/pins'
import toaster from './toaster'

const store = createStore({
  plugins: [createLogger()],
  state: {
    lang: 'en',
    market: 'EN',
    accessToken: 'anonymous'
  },
  modules: {
    topbar,
    toaster,
    capability,
    pins
  }
})

export default store
