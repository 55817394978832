<template>
  <div>
    <ConfirmModal
      :show="showConfirm"
      title="Confirm Action"
      :message="$t('CAPABILITIES.CONFIRM_RESET_SERVICEPOINT')"
      :is-loading="loading"
      @cancel="showConfirm = false"
      @confirm="resetCapability"
    />
    <RuleValueOnlyModal
      :rule="ruleModal.rule"
      :options="ruleModal.options"
      @did-save="handleSaveRule"
      @did-close="ruleModal.options.show = false"
    />
    <div class="flex-group spaced-between">
      <Breadcrumb :title="sourceId" />
      <div v-show="!loading" class="flex-group">
        <pn-tooltip color="blue700" position="bottom">
          {{ $t('CAPABILITIES.RESET_TOOLTIP') }}
        </pn-tooltip>
        <pn-button
          appearance="light"
          class="reset-sp-button"
          @click="showConfirm = true"
        >
          {{ $t('CAPABILITIES.RESET') }}
        </pn-button>
      </div>
    </div>
    <div class="capabilities-main-section">
      <NamespaceMenu
        :namespaces="namespaces"
        :loading="loading"
        :filter="options.filter"
        :selected="options.namespace"
        @did-change-selection="options.namespace = $event"
        @did-change-filter="options.filter = $event"
      />
      <div class="capabilities-content">
        <Loader v-if="loading" />
        <div v-if="filtered.length">
          <CapabilityItem
            v-for="(item, index) in filtered"
            :key="index"
            :title="item.title"
            :item="item.data"
            @will-update-rule="handleWillUpdateRule"
          />
        </div>
        <EmptyContent v-else v-show="!loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { isValidSsid, getSentenceCase } from '@/shared/utils/capabilityUtils'
import { useCapabilityUtils } from '@/composables/useCapabilityUtils'
import { useStoreSubscription } from '@/composables/useStoreSubscription.js'
import { usePreloadCapabilities } from '@/composables/usePreloadCapabilities.js'
import useToast from '@/shared/composables/useToast'

import Breadcrumb from '@/components/Breadcrumb'
import CapabilityItem from '@/components/Servicepoint/CapabilityItem'

import NamespaceMenu from '@/components/NamespaceMenu.vue'
import RuleValueOnlyModal from '../components/Modals/RuleValueOnlyModal.vue'
import i18n from '@/i18n/i18n'

export default {
  name: 'Servicepoint',
  components: {
    Breadcrumb,
    CapabilityItem,
    NamespaceMenu,
    RuleValueOnlyModal
  },
  setup() {
    usePreloadCapabilities()
    const {
      namespaces,
      options,
      getFilteredByNamespace,
      setNamespaces,
      getAppliedFilters,
      getFilteredByQuery,
      filtered,
      successToast,
      errorToast
    } = useCapabilityUtils()
    const { showToaster } = useToast()
    const { storeSubscription } = useStoreSubscription()
    return {
      namespaces,
      options,
      getFilteredByNamespace,
      setNamespaces,
      getAppliedFilters,
      getFilteredByQuery,
      filtered,
      successToast,
      errorToast,
      showToaster,
      storeSubscription
    }
  },
  data() {
    return {
      sourceId: '',
      showConfirm: false,
      ruleModal: {
        rule: {
          key: 'Default',
          value: ''
        },
        options: {
          show: false,
          title: '',
          allowRemove: false,
          allowSave: true,
          isLoading: false,
          capability: null
        }
      }
    }
  },
  computed: {
    ...mapState('capability', ['loading', 'servicepoint', 'capabilities']),
    ...mapState(['accessToken'])
  },
  watch: {
    servicepoint(val) {
      this.filtered = this.mapServicepoint(val)
      this.filtered = this.getAppliedFilters(this.filtered)
    },
    'options.filter': function (val) {
      const unfiltered = this.mapServicepoint(this.servicepoint)
      this.filtered = unfiltered
      this.filtered = this.getFilteredByQuery(this.filtered, val)
      this.setNamespaces(this.filtered)
      this.filtered = this.getFilteredByNamespace(unfiltered)
    },
    'options.namespace': function () {
      const unfiltered = this.mapServicepoint(this.servicepoint)
      this.filtered = this.getFilteredByNamespace(unfiltered)
    },
    '$route.params.id': function () {
      this.reloadCurrentSourceId()
    },
    accessToken() {
      this.reloadCurrentSourceId()
    }
  },
  mounted() {
    this.subscribeMutations()
    this.reloadCurrentSourceId()
  },
  methods: {
    ...mapActions('capability', [
      'updateCapability',
      'patchCapabilitySourceId',
      'getServicepointCapabilities'
    ]),
    getSentenceCase,
    subscribeMutations() {
      this.storeSubscription = this.$store.subscribe((mutation) => {
        if (mutation.type === 'capability/updateCapabilitySuccess') {
          this.successToast.message = i18n.global.t(
            'CAPABILITIES.SERVICEPOINT_UPDATE_SUCCESS'
          )
          this.showToaster(this.successToast)
          this.getServicepoint(this.sourceId)
          this.ruleModal.options.show = false
          this.ruleModal.options.isLoading = false
          this.showConfirm = false
        }
      })
    },
    reloadCurrentSourceId() {
      const { id } = this.$route.params
      if (this.accessToken !== 'anonymous' && id !== undefined) {
        this.getServicepoint(id)
      }
    },
    getServicepoint(id) {
      if (isValidSsid(id)) {
        this.sourceId = id
        this.getServicepointCapabilities(id)
      } else {
        this.errorToast.message =
          'Unable to load servicepoint. Invalid SSID provided.'
        this.showToaster(this.errorToast)
        this.$router.push({ path: '/' })
      }
    },
    getCapability(capabilityId) {
      const capability = this.capabilities.filter(
        (item) => item.capabilityId.toLowerCase() === capabilityId.toLowerCase()
      )
      return capability.length ? capability[0] : null
    },
    getCapabilityId(property) {
      return `${this.options.namespace}.${property}`
    },
    mapServicepoint(servicepoint) {
      return Object.keys(servicepoint).map((key) => {
        return {
          title: key,
          data: servicepoint[key]
        }
      })
    },
    showUnableToUpdate(capabilityId) {
      this.errorToast.message = `Unable to update this rule. Capability ID: ${capabilityId}, 
          editing this rule may not be supported.`
      this.showToaster(this.errorToast)
    },
    resetCapability() {
      let payload = this.capabilities.filter(
        (item) => item.sourceIds && item.sourceIds[this.sourceId]
      )
      payload = payload.map((item) => {
        delete item.sourceIds[this.sourceId]
        return item
      })
      const items = []
      payload.map((item) => {
        items.push(item)
      })
      this.updateCapability(items)
    },
    getDataByFiltered(data) {
      if (this.options.filter) {
        const newData = {}
        const filter = this.options.filter.toLowerCase()
        Object.keys(data).forEach((key) => {
          if (key.toLowerCase().includes(filter)) {
            newData[key] = data[key]
          }
        })
        return newData
      }
      return data
    },
    handleWillUpdateRule(event) {
      const capability = this.getCapability(
        this.getCapabilityId(event.rule.key)
      )
      if (capability) {
        this.ruleModal.rule = event.rule
        this.ruleModal.options.show = true
        this.ruleModal.options.capability = capability
        this.ruleModal.options.title = `Edit capability ${getSentenceCase(
          event.rule.key
        )} for ${this.sourceId}`
      } else {
        this.showUnableToUpdate(event.rule.key)
      }
    },
    handleSaveRule(rule) {
      const capability = this.getCapability(this.getCapabilityId(rule.key))
      if (capability) {
        const payload = { ...capability }
        if (!payload.sourceIds || payload.sourceIds === undefined) {
          payload.sourceIds = {}
        }
        this.ruleModal.options.isLoading = true

        if (payload.sourceIds[this.sourceId] === undefined) {
          payload.sourceIds[this.sourceId] = rule.value
          this.updateCapability(payload)
        } else {
          const params = {
            capabilityId: capability.capabilityId,
            sourceId: this.sourceId,
            value: rule.value
          }
          this.patchCapabilitySourceId(params)
        }
      } else {
        this.ruleModal.options.show = false
        this.ruleModal.options.isLoading = false
        this.showUnableToUpdate(rule.key)
      }
    }
  }
}
</script>
