import { ref, reactive, computed, watch, defineEmits } from 'vue'
import { hasDifferences } from '@/shared/utils/capabilityUtils.js'

export function useRuleModal(props, context) {
  const draft = reactive({
    key: '',
    value: ''
  })
  const error = ref('')

  const didMadeEdits = computed(() => {
    return (
      props.rule.key !== draft.key ||
      hasDifferences(props.rule.value, draft.value)
    )
  })

  watch(
    () => props.options.show,
    (show) => {
      if (!show) {
        draft.key = ''
        draft.value = ''
        error.value = ''
      }
    }
  )

  watch(
    () => props.rule,
    (rule) => {
      draft.key = rule.key
      draft.value = rule.value
    }
  )

  defineEmits(['didSave', 'didClose', 'didRemove'])

  const save = () => {
    context.emit('didSave', draft)
  }

  const close = () => {
    context.emit('didClose')
  }

  const remove = () => {
    context.emit('didRemove', draft)
  }

  return {
    draft,
    error,
    didMadeEdits,
    save,
    close,
    remove
  }
}
