<template>
  <BaseRuleModal
    :options="options"
    :is-save-enabled="
      options.allowSave && error === '' && keyError === '' && didMadeEdits
    "
    :error="error"
    class="source-id-modal"
    @did-save="save"
    @did-remove="remove"
    @did-close="close"
    @did-toggle-deleting="handleToggleDeletion"
  >
    <template #default="slotProps">
      <pn-input
        label="Source ID"
        :value="draft.key"
        :error="isComplex ? '' : keyError"
        :disabled="isComplex || options.isLoading || slotProps.isDeleting"
        @input="draft.key = $event.target.value"
      />
      <RuleValueControl
        :value="draft.value"
        :capability="options.capability"
        :disabled="isComplex || options.isLoading || slotProps.isDeleting"
        :is-active="options.show"
        @did-change="draft.value = $event"
        @did-validate="handleValueValidation"
      />
    </template>
  </BaseRuleModal>
</template>

<script>
import ruleModalMixin from './mixins/ruleModalMixin.js'
import { useRuleModal } from '@/composables/useRuleModal.js'
import { useValueTypeUtils } from '@/composables/useValueTypeUtils.js'
import { isValidSsid } from '@/shared/utils/capabilityUtils.js'
import i18n from '@/i18n/i18n'

export default {
  name: 'RuleSourceIdModal',
  mixins: [ruleModalMixin],
  setup(props, context) {
    const { draft, didMadeEdits, error, save, close, remove } = useRuleModal(
      props,
      context
    )
    const { checkIfComplex, checkIfBasicArray } = useValueTypeUtils()
    return {
      draft,
      didMadeEdits,
      error,
      checkIfComplex,
      checkIfBasicArray,
      save,
      close,
      remove
    }
  },
  computed: {
    keyError() {
      return isValidSsid(this.draft.key)
        ? ''
        : i18n.global.t('CAPABILITIES.SOURE_ID_FORMAT_WARNING')
    }
  }
}
</script>
