<template>
  <BaseRuleModal
    :options="options"
    :is-save-enabled="options.allowSave && error === '' && didMadeEdits"
    class="value-only-modal"
    @did-save="save"
    @did-remove="remove"
    @did-close="close"
    @did-toggle-deleting="handleToggleDeletion"
  >
    <template #default="slotProps">
      <pn-input :value="draft.key" label="Key" disabled />
      <RuleValueControl
        :value="draft.value"
        :capability="options.capability"
        :disabled="isComplex || options.isLoading || slotProps.isDeleting"
        :is-active="options.show"
        @did-change="draft.value = $event"
        @did-validate="handleValueValidation"
      />
    </template>
  </BaseRuleModal>
</template>

<script>
import ruleModalMixin from './mixins/ruleModalMixin.js'
import { useRuleModal } from '@/composables/useRuleModal.js'
import { useValueTypeUtils } from '@/composables/useValueTypeUtils.js'

export default {
  name: 'RuleValueOnlyModal',
  mixins: [ruleModalMixin],
  setup(props, context) {
    const { draft, didMadeEdits, error, save, close, remove } = useRuleModal(
      props,
      context
    )
    const { checkIfComplex, checkIfBasicArray } = useValueTypeUtils()
    return {
      draft,
      didMadeEdits,
      error,
      checkIfComplex,
      checkIfBasicArray,
      save,
      close,
      remove
    }
  }
}
</script>
