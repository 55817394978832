// Note: Make sure to add valuetype as all small-case
export const valueTypes = {
  'clientcapability.cameratimeoutms': 'number',
  'administration.enable': 'boolean',
  'administration.appsettingsvisible': 'boolean',
  'administration.inventoryvisible': 'boolean',
  'administration.prognosvisible': 'boolean',
  'administration.customerserviceuri': 'string',
  'administration.backofficeuri': 'string',
  'administration.contactphonenumber': 'string',
  'administration.cancelevents': 'boolean',
  'administration.iosappdownloadurl': 'string',
  'arrival.enable': 'boolean',
  'arrival.shelfnumberformat': 'string',
  'arrival.shelfidfirst': 'boolean',
  'arrival.arrivalwithoutid': 'boolean',
  'arrival.restrictions': 'boolean',
  'delivery.canhandlepayments': 'boolean',
  'delivery.enable': 'boolean',
  'delivery.identificationtypes.identificationtype': 'object',
  'delivery.signonglass.photosignatureallowed': 'boolean',
  'changeitemdelivery.enable': 'boolean',
  'whitelabel.enable': 'boolean',
  'whitelabel.itemidvalidationrulesextended': 'boolean',
  'receiveitem.changeweightallowed': 'boolean',
  'receiveitem.enable': 'boolean',
  'receiveitem.receiptprintout': 'boolean',
  'receiveitem.shelfplacementallowed': 'boolean',
  'receiveitem.shelfnumberformat': 'string',
  'receiveitem.prepaidlabelprintout': 'boolean',
  'receiveitem.printidformat': 'string',
  'departure.enable': 'boolean',
  'departure.valuemail': 'boolean',
  'departure.dropofftimes.parcel': 'string',
  'departure.dropofftimes.valuemail': 'string',
  'return.enable': 'boolean',
  'return.shelfplacementallowed': 'boolean',
  'return.shelfnumberformat': 'string',
  'return.returnreasons.returnreason': 'object',
  'checkout.enable': 'boolean',
  'checkout.reasons': 'object',
  'wronglysorted.enable': 'boolean',
  'deliverybooking.enable': 'boolean',
  'flexbooking.enable': 'boolean',
  'flexbooking.homedeliveryenabled': 'boolean',
  'search.itemsearch': 'boolean',
  'search.eventsearch': 'boolean',
  'message.enable': 'boolean',
  'message.maxbodysize': 'string',
  'remindernotification.enable': 'boolean',
  'pointofsale.enable': 'boolean',
  'pointofsale.url': 'string',
  'pointofsale.clientname': 'string',
  'handovertopostnord.enable': 'boolean',
  'handovertopostnord.reasons': 'object',
  'forwardmail.enable': 'boolean',
  'handoverforruraldelivery.enable': 'boolean',
  'logattributes.sourceid': 'string',
  'logattributes.countrycode': 'string',
  'powerofattorney.enable': 'boolean',
  'powerofattorney.url': 'string',
  'transactionreconciliation.enable': 'boolean',
  'selfservice.prepaidlabelprintout': 'boolean',
  'selfservice.receiveitem': 'boolean',
  'selfservice.enable': 'boolean',
  'selfservice.pointofsaleurl': 'string',
  'missions.enable': 'boolean',
  'history.historypageurl': 'string',
  'cashier.refundtimelimit': 'number'
}
