import { reactive } from 'vue'
import { useStore } from 'vuex'

export default function useToast() {
  const store = useStore()
  const successToast = reactive({
    showClose: true,
    icon: 'check',
    appearance: 'success',
    message: '',
    show: true
  })

  return {
    successToast,
    showToaster: (toast) => store.dispatch('toaster/showToaster', toast)
  }
}
